import axios from "axios";
import React, { useState, useEffect } from "react";
import ReactModal from "../../Components/Modals";
import StandardDate from "../../Components/StandardDate";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useNavigate } from "react-router-dom";

const PaymentHistory = ({
  paymentList,
  customerID,
  // reservationForDate,
  rateDetailAmt,
  rateDetailPax,
  reservatorID,
  setDetailList,
  state,
  token,
  handleClose,
  seterror,
  handleOpen,
  handleCloseEditPartyadvancepaymentError,
}) => {
  const navigate = useNavigate();
  const refreshPage = () => {
    navigate(0);
  };

  const [paymentAmt, setPaymentAmt] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(paymentList[0]?.advancePaymentMethod || "");
  // let selectedReservationForDate = new Date(reservationForDate).toISOString().substring(0, 10);

  let baseUrl = process.env.REACT_APP_BASE_URL;

  let total = rateDetailAmt * rateDetailPax;

  const calculatVAT = () => {
    const obtainedVAT = 0.13 * total;
    const totalAfterVAT = total + obtainedVAT;
    const remainingBalance = totalAfterVAT - paymentAmt;
    const onlyVAT = obtainedVAT;
    const afterVAT = totalAfterVAT;
    const balance = remainingBalance;
    return [onlyVAT, afterVAT, balance];
  };

  //store the returned array into an array
  const [onlyVAT, afterVAT, balance] = calculatVAT();

  useEffect(() => {
    paymentList.forEach((item) => {
      setPaymentAmt(item.PaymentAmount);
    });
  }, [paymentList]);

  const [finaliseModal, setFinaliseModal] = useState(false);

  const handleCloseFinaliseModal = () => {
    setFinaliseModal(false);
    refreshPage();
  };
  const handleShowFinaliseModal = () => setFinaliseModal(true);

  const handleFinaliseModal = () => {
    handleShowFinaliseModal();
  };

  const handleFinalise = (id) => {
    if (id) {
      axios
        .post(`${baseUrl}/finalize`, {
          customerID: `${id}`,
          banquetreservationID: `${reservatorID}`,
          token: `${token}`,
        })
        .then((res) => {
          // console.log(res)
          handleCloseFinaliseModal();
        })
        .catch((error) => {
          // console.log(error)
        });
    }
    setTimeout(() => {
      axios
        .post(`${baseUrl}/getStarted`, {
          token: `${token}`,
        })
        .then((response) => {
          setDetailList(response.data);
          refreshPage();
        })
        .catch((error) => {
          // console.log(error.response.data, "error")
          setDetailList([]);
        });
    }, 1000);
  };

  const [cancelModal, setCancelModal] = useState(false);
  const handleCloseModal = () => {
    setCancelModal(false);
    refreshPage();
  };
  const handleShowModal = () => {
    setCancelModal(true);
  };

  const handleShowCancelModal = () => {
    handleShowModal();
  };

  const handleCancel = (id) => {
    if (id) {
      axios
        .post(`${baseUrl}/cancel`, {
          customerID: `${id}`,
          banquetreservationID: `${reservatorID}`,
          token: `${token}`,
        })
        .then((res) => {
          // console.log(res)
          handleCloseModal();
        })
        .catch((error) => {
          // console.log(error)
        });
    }

    axios
      .post(`${baseUrl}/getStarted`, {
        token: `${token}`,
      })
      .then((response) => {
        setDetailList(response.data);
        refreshPage();
      })
      .catch((error) => {
        // console.log(error)
      });
  };

  const checkNan = (sales) => {
    if (sales) {
      let value = parseFloat(sales).toLocaleString(undefined, {
        maximumFractionDigits: 2,
      });
      return value;
    } else {
      return "";
    }
  };

  const [changeAdvance, setchangeAdvance] = useState(false);

  const changeAdvanceButtonClick = () => {
    setchangeAdvance(true);
  };

  const [advanceAmountChange, setadvanceAmountChange] = useState(0);
  const changeadvamount = async (e) => {
    setadvanceAmountChange(e.target.value || 0);
  };

  const changeAdvanceAmount = async () => {
    axios
      .post(`${baseUrl}/changeAdvancePayment`, {
        token: `${token}`,
        banquetReservationID: `${reservatorID}`,
        advanceAmount: advanceAmountChange || 0,
        paymentMethod:paymentMethod||paymentList[0]?.advancePaymentMethod || "",
      })
      .then((response) => {
        // console.log(response)
        // handleClose()
        // seterror(response.data.success)
        // handleOpen()
        refreshPage();
      })
      .catch((error) => {
        handleCloseEditPartyadvancepaymentError();
        seterror(error.response.data.error || "error");
        handleOpen();
      });
  };

  const changePaymentMethod = (e)=>{
    if(e?.target?.value){
        setPaymentMethod(e?.target?.value)
    };
  };



  return (
    <div className="border-payment-history">
      <div className="responsive-payment-history-table">
        <table className="payment-history-table">
          <thead>
            <tr className="position-sticky">
              <th>SN</th>
              <th>Date</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {paymentList.map((pay, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
 
                  {pay.paymentDate && pay.paymentDate!=="" && <StandardDate date={pay.paymentDate} />}
                </td>
                <td>{pay.PaymentAmount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div></div>
      <div className="style-payment-footer">
        <div className="calculations-payment">
          <label>
            Sub-Total : <span>{checkNan(total)}</span>
          </label>
          <label>
            VAT : <span>{checkNan(onlyVAT)}</span>
          </label>
          <label>
            Total : <span>{checkNan(afterVAT)}</span>
          </label>
          {/* {console.log(afterVAT,paymentAmt,afterVAT===parseFloat(paymentAmt||"0.00"))} */}
          <label>
            Advance : <span>{checkNan(paymentAmt)}</span>{" "}
            <button className="btn-finalise" onClick={changeAdvanceButtonClick}>
              Change
            </button>
          </label>
          {changeAdvance && (
            <InputGroup className="mb-3">
              <Form.Control
                placeholder={checkNan(paymentAmt) || 0}
                aria-label="Enter the amount"
                aria-describedby="basic-addon2"
                onChange={changeadvamount}
                type="number"
                step="any"
                min="0"
              />
              <Form.Select aria-label="Default select example" onChange={(e)=>{changePaymentMethod(e)}}>
                <option>{paymentList[0]?.advancePaymentMethod || ""}</option>
                {(paymentList[0]?.advancePaymentMethod || "")!=="Mobile Payment" &&<option value="Mobile Payment">Mobile Payment</option>}
                {(paymentList[0]?.advancePaymentMethod || "")!=="Cash" &&<option value="Cash">Cash</option>}
                {(paymentList[0]?.advancePaymentMethod || "")!=="Cheque" &&<option value="Cheque">Cheque</option>}
              </Form.Select>
              <Button
                variant="outline-secondary"
                id="button-addon2"
                onClick={changeAdvanceAmount}>
                Confirm
              </Button>
            </InputGroup>
          )}
          <label>
            Remaining Balance :<span>{checkNan(balance)}</span>{" "}
          </label>
        </div>

        {state === "Cancelled" ? (
          ""
        ) : (
          <div className="payment-button">
            <button className="btn-finalise" onClick={handleFinaliseModal}>
              Finalise
            </button>
            <button className="btn-cancel" onClick={handleShowCancelModal}>
              Cancel
            </button>
          </div>
        )}
        <ReactModal
          show={cancelModal}
          message={"Are you sure you want to cancel?"}
          buttonOne={"Yes"}
          buttonTwo={"No"}
          handleTarget={() => handleCancel(customerID)}
          handleClose={handleCloseModal}
        />
        <ReactModal
          show={finaliseModal}
          message={"Are you sure you want to finalise?"}
          buttonOne={"Yes"}
          buttonTwo={"No"}
          handleTarget={() => handleFinalise(customerID)}
          handleClose={handleCloseFinaliseModal}
        />
      </div>
    </div>
  );
};

export default PaymentHistory;
